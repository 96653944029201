.legal-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 1rem; /* 上下のパディングを増やしました */
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 3rem; /* マージンを増やしました */
  text-align: center;
}

.legal-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.legal-table th,
.legal-table td {
  padding: 1rem;
  border: 1px solid #e0e0e0;
}

.legal-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: left;
  width: 30%;
}

.legal-table td {
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .legal-section {
    padding: 3rem 1rem; /* モバイル用にパディングを調整 */
  }

  .section-title {
    margin-bottom: 2rem; /* モバイル用にマージンを調整 */
  }

  .legal-table th,
  .legal-table td {
    display: block;
    width: 100%;
  }

  .legal-table th {
    border-bottom: none;
  }
}