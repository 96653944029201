.contact {
  background-color: white;
  color: #333;
  padding: 80px 0;
  width: 100%;
}

.section-contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}


.contact-title {
  font-size: 1.5rem; /* フォントサイズを小さく */
  font-weight: 600; /* フォントの太さを少し調整 */
  margin-bottom: 1rem; /* 下のマージンを追加して空間を作る */
  color: #333; /* 色を少し濃くして読みやすく */
}

.contact-description {
  text-align: center;
  margin-bottom: 3rem;
  color: #666;
}

.contact form {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* フォーム全体の最大幅を設定 */
  margin: 0 auto; /* 中央寄せ */
}

.form-group {
  margin-bottom: 2rem;
  width: 100%; /* 幅を100%に設定 */
}

.input-container {
  position: relative;
  width: 100%; /* 幅を100%に設定 */
}

.input-container input,
.input-container select,
.input-container textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box; /* パディングを含めた幅に設定 */
}

.input-container label {
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  background-color: white;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  color: #666;
  transition: all 0.3s ease;
}

.input-container input:focus,
.input-container select:focus,
.input-container textarea:focus {
  outline: none;
  border-color: #000;
}

.input-container input:focus + label,
.input-container select:focus + label,
.input-container textarea:focus + label {
  color: #000;
  font-weight: bold;
}

.input-container textarea {
  height: 150px;
  resize: vertical;
}

.form-group.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group.checkbox input {
  width: auto;
  margin-right: 0.5rem;
}

.required {
  color: #ff4136;
  margin-left: 0.25rem;
}

.contact button {
  background-color: #000;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  align-self: center;
  margin-top: 2rem;
}

.contact button:hover {
  background-color: #333;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .contact {
    padding: 50px 0;
  }

  .section-contact {
    padding: 0 15px;
  }

  .contact h2 {
    font-size: 2rem;
  }

  .contact form {
    max-width: 100%; /* モバイル時はフル幅に */
  }

  .input-container input,
  .input-container select,
  .input-container textarea {
    font-size: 0.9rem;
  }

  .contact button {
    width: 100%;
  }
}