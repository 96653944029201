:root {
  --main-bg-color: #ffffff;
  --text-color: #333333;
  --accent-color: #f5e6d3;
  --secondary-color: #f0f0f0;
  --highlight-color: #e0c9a6;
  --service-bg-color: #faf7f3;
}

.App {
  text-align: center;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  padding: 20px 5%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  pointer-events: auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, padding 0.3s ease;
  height: auto;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

header.scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 5%;
}

.logo-container {
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: bold;
  padding-bottom: 3px;
  color: var(--text-color);
  margin-right: auto;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-left: auto;
  margin-right: 20px;
}

.nav-item {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  transition: transform 0.3s ease, color 0.3s ease;
  color: var(--text-color);
  font-size: 0.9rem;
  padding: 5px 10px;
}

.nav-item:hover,
.nav-item:focus {
  transform: translateY(-2px);
  color: var(--highlight-color);
}

.contact-button-container {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  text-align: left;
  padding-left: 20px;
}

.contact-button {
  color: var(--text-color);
  padding: 8px 16px;
  border: 2px solid var(--text-color);
  border-radius: 25px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.contact-button:focus,
.contact-button:hover {
  color: var(--main-bg-color);
  background-color: var(--text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--main-bg-color);
  z-index: 1;
}

.section-wrapper {
  width: 100%;
  padding: 80px 0;
}

.section {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  width: 100%;
}

.section-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section h2 {
  font-size: 2.5rem;
  margin-bottom: 5rem;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  display: inline-block;
  padding-bottom: 10px;
}

.section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 3px;
  background-color: var(--text-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

#home {
  background: none;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.home-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  height: 100%;
}

.hero-image-container {
  flex: 0 0 45%;
  max-width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 0.6s;
}

.title-container {
  flex: 0 0 55%;
  max-width: 55%;
  text-align: left;
  color: var(--text-color);
  z-index: 2;
}

.title-text {
  font-size: clamp(36px, 6vw, 64px);
  line-height: 1.2;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 0.2s;
}

.animated-text {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
  margin-right: 10px;
  margin-bottom: 5px;
}

.animated-text:nth-child(1) { animation-delay: 0.5s; }
.animated-text:nth-child(2) { animation-delay: 0.7s; }
.animated-text:nth-child(3) { animation-delay: 0.9s; }

.subtitle {
  font-size: clamp(16px, 2vw, 20px);
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 100%;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 0.4s;
}

#about-wrapper { background-color: var(--secondary-color); }
#news-wrapper { background-color: var(--main-bg-color); }
#service-wrapper { background-color: var(--service-bg-color); }

.news-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 30px;
}

.news-item {
  background-color: var(--secondary-color);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.news-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-item:hover .news-image img {
  transform: scale(1.05);
}

.news-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.news-meta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.news-date {
  font-size: 0.8rem;
  color: #666666;
  margin-right: 10px;
}

.news-category {
  font-size: 0.7rem;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: transparent;
  border: 1px solid #999999;
  color: #666666;
}

.news-category.blog,
.news-category.news {
  background-color: transparent;
  color: #666666;
}

.news-title {
  font-size: 1.1rem;
  margin-bottom: 8px;
  color: var(--text-color);
  font-weight: 600;
}

.news-excerpt {
  font-size: 0.9rem;
  color: #4a4a4a;
  margin-bottom: 15px;
  line-height: 1.5;
}

.news-more-button,
.details-button {
  margin-top: 20px;
  padding: 8px 16px;
  background-color: transparent;
  color: var(--text-color);
  border: 2px solid var(--text-color);
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 150px;
}

.news-more-button::after,
.details-button::after {
  content: ' >';
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.news-more-button:hover,
.news-more-button:focus,
.details-button:hover,
.details-button:focus {
  color: var(--main-bg-color);
  background-color: var(--text-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.news-more-button:hover::after,
.news-more-button:focus::after,
.details-button:hover::after,
.details-button:focus::after {
  transform: translateX(3px);
}

.service-item {
  display: flex;
  align-items: stretch;
  margin-bottom: 60px;
  background-color: var(--main-bg-color);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 20px;
}

.service-item:nth-child(even) {
  flex-direction: row-reverse;
}

.service-image {
  flex: 0 0 40%;
  max-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
}

.service-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100%;
  border-radius: 10px;
}

.service-content {
  flex: 1;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-content-inner {
  flex-grow: 1;
  width: 100%;
  margin-bottom: 20px;
}

.service-content h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: var(--text-color);
}

.service-content p {
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 1.1rem;
}

.service-content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.service-content li {
  margin-bottom: 10px;
  color: var(--text-color);
  font-size: 1rem;
}

.footer {
  background-color: var(--text-color);
  color: var(--main-bg-color);
  padding: 2rem 0;
  margin-top: auto;
}

.footer-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer p {
  margin-bottom: 1rem;
}

.footer-nav {
  margin-bottom: 1rem;
}

.footer-nav a,
.social-links a {
  color: var(--main-bg-color);
  text-decoration: none;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.footer-nav a:hover,
.social-links a:hover {
  color: #ccc;
}

.social-links a {
  font-size: 1.2rem;
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .home-content {
    flex-direction: column-reverse;
    padding: 15px;
    height: auto;
  }

  .title-container,
  .hero-image-container {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .hero-image-container {
    margin-bottom: 20px;
  }

  .title-text {
    font-size: clamp(28px, 7vw, 48px);
    text-align: center;
  }

  .subtitle {
    font-size: clamp(14px, 3.5vw, 18px);
    text-align: center;
  }
}

a {
  text-decoration: none;
}

.nav-item,
.footer-nav a,
.social-links a {
  text-decoration: none;
}

/* コンタクトセクションのスタイル */
#contact-wrapper {
  background-color: var(--main-bg-color);
  width: 100%;
}

#contact {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: var(--main-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.required {
  color: #ff0000;
}

.submit-button {
  background-color: var(--text-color);
  color: var(--main-bg-color);
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: var(--highlight-color);
}

@media (max-width: 768px) {
  #contact {
    padding: 60px 15px;
  }

  .contact-form {
    padding: 20px;
  }

  .submit-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  header {
    flex-wrap: wrap;
    height: auto;
    padding: 15px 5%;
  }

  .logo-container {
    font-size: clamp(1.7rem, 4vw, 2.2rem);
    flex: 0 0 auto;
  }

  nav {
    width: 100%;
    justify-content: flex-end;
  }

  .nav-item {
    flex: 0 0 auto;
    margin-left: 1rem;
    text-align: center;
    font-size: 0.9rem;
    padding: 18px 22px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .contact-button-container {
    flex-basis: auto;
    justify-content: flex-start;
    margin-top: 12px;
    width: auto;
    text-align: left;
  }

  .contact-button {
    display: inline-flex;
    width: auto;
    text-align: left;
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .section-wrapper {
    padding: 60px 0;
  }

  .section {
    padding: 0 5%;
  }

  #home {
    justify-content: center;
    align-items: center;
  }

  .title-text {
    font-size: clamp(24px, 6vw, 40px);
    margin-bottom: 15px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .subtitle {
    font-size: clamp(12px, 3.5vw, 15px);
    margin-bottom: 15px;
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .service-item,
  .service-item:nth-child(even) {
    flex-direction: column;
  }

  .service-image {
    max-width: 100%;
    height: 200px;
    padding: 0;
  }

  .service-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  .footer-nav,
  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-nav a,
  .social-links a {
    margin: 0.5rem 0;
  }

  .nav-container {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-container.open {
    display: block;
  }

  nav {
    flex-direction: column;
    width: 100%;
  }

  .contact-button-container {
    display: none;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .hamburger-menu span {
    width: 30px;
    height: 3px;
    background: var(--text-color);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .hamburger-menu.open span:first-child {
    transform: rotate(45deg);
  }

  .hamburger-menu.open span:nth-child(2) {
    opacity: 0;
  }

  .hamburger-menu.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .hero-image {
    object-position: center;
    max-height: 35vh;
  }

  .title-text {
    font-size: clamp(24px, 7vw, 42px);
  }

  .subtitle {
    font-size: clamp(12px, 1.6vw, 14px);
    max-width: 85%;
  }

  .home-content {
    flex-direction: column;
    padding: 15px;
    height: 100vh;
    justify-content: center;
  }

  .hero-image-container,
  .title-container {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .hero-image-container {
    order: -1;
    margin-bottom: 15px;
    height: auto;
  }

  .hero-image {
    max-height: 35vh;
    object-fit: contain;
  }

  .title-container {
    text-align: center;
  }

  .title-text {
    font-size: clamp(24px, 7vw, 42px);
    margin-bottom: 10px;
  }

  .animated-text {
    display: inline-block;
    margin-right: 5px;
  }

  .subtitle {
    font-size: clamp(12px, 3.5vw, 15px);
    margin-bottom: 15px;
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  #home {
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .title-text {
    font-size: clamp(24px, 6vw, 40px);
    margin-bottom: 15px;
  }

  .animated-text {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .news-list {
    grid-template-columns: 1fr;
  }

  .news-item {
    max-width: 100%;
  }

  .service-item {
    padding: 0;
  }

  .service-image {
    max-width: 100%;
    height: 200px;
    padding: 0;
  }

  .service-image img {
    border-radius: 0;
  }

  .service-content {
    padding: 20px;
  }

  .title-text {
    font-size: clamp(24px, 6vw, 36px);
  }

  .subtitle {
    font-size: clamp(12px, 3.5vw, 16px);
  }
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: 1;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.title-container {
  z-index: 2;
}

.animated-text {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
}

.animated-text:nth-child(1) {
  animation-delay: 0.5s;
}

.animated-text:nth-child(2) {
  animation-delay: 0.7s;
}

.animated-text:nth-child(3) {
  animation-delay: 0.9s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-text {
  font-size: clamp(28px, 5vw, 48px);
  color: var(--text-color);
  line-height: 1.3;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.subtitle {
  font-size: clamp(14px, 1.8vw, 18px);
  color: var(--text-color);
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 100%;
}

.cta-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .title-text {
    font-size: clamp(24px, 6vw, 36px);
  }

  .subtitle {
    font-size: clamp(12px, 3.5vw, 16px);
  }
}

/* コンタクトセクションのスタイル */
#contact-wrapper {
  background-color: var(--main-bg-color);
  width: 100%;
}

#contact {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: var(--main-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.required {
  color: #ff0000;
}

.submit-button {
  background-color: var(--text-color);
  color: var(--main-bg-color);
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: var(--highlight-color);
}

@media (max-width: 768px) {
  #contact {
    padding: 60px 15px;
  }

  .contact-form {
    padding: 20px;
  }

  .submit-button {
    width: 100%;
  }
}
.company-info {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.company-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.company-table th,
.company-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

.company-table th {
  width: 25%;
  background-color: #f8f9fa;
  font-weight: 600;
  color: #374151;
}

.company-table td {
  width: 75%;
  color: #4b5563;
}

.company-table tr:last-child th,
.company-table tr:last-child td {
  border-bottom: none;
}

@media (max-width: 640px) {

  .company-table th,
  .company-table td {
    padding: 0.75rem;
  }

  .company-table th {
    width: 35%;
  }

  .company-table td {
    width: 65%;
  }
}