.footer {
  background-color: var(--primary-color);
  color: var(--text-color-light);
  padding: 2rem 0;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-links {
  margin-bottom: 1.5rem;
}

.footer-link {
  color: var(--text-color-light);
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s ease;
  font-size: 0.9rem;
}

.footer-link:hover {
  color: var(--highlight-color);
}

.footer-recruitment {
  margin-bottom: 1.5rem;
}

.recruitment-text {
  font-weight: bold;
  color: var(--highlight-color);
  margin-bottom: 0.5rem;
}

.recruitment-link {
  color: var(--highlight-color);
  text-decoration: none;
  font-size: 0.9rem;
}

.recruitment-link:hover {
  text-decoration: underline;
}

.footer-copyright {
  font-size: 0.8rem;
  color: var(--text-color-light);
  opacity: 0.8;
}

@media (max-width: 768px) {
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-link {
    margin: 0.5rem 0;
  }
}