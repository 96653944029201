.thank-you-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0a0a;
  color: #ffffff;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  overflow-y: auto;
}

.thank-you-content {
  text-align: center;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
}

.thank-you-title {
  font-size: 4rem;
  font-weight: 300;
  margin-bottom: 1rem;
  letter-spacing: 0.1em;
}

.thank-you-message {
  text-align: center;
  margin-bottom: 2rem;
}

.thank-you-message p {
  margin-bottom: 1rem;
}

.divider {
  width: 50px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 2rem auto;
}

.company-name {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.2em;
  margin-bottom: 2rem;
}

.back-link {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  padding: 0.8rem 1.5rem;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.back-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.back-link::before {
  content: '←';
  margin-right: 0.5em;
}

@media (max-width: 768px) {
    .thank-you-container {
        position: fixed;
        min-height: 100vh;
    }


  .thank-you-title {
    font-size: 3rem;
  }

  .thank-you-message {
    font-size: 1rem;
  }
}

#thank-you-wrapper {
  background-color: #f5f5f5;
  min-height: calc(100vh - 60px); /* ヘッダーの高さを考慮 */
}

#thank-you {
  padding: 80px 0;
  width: 100%;
}

.thank-you-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.thank-you-message {
  margin-bottom: 2rem;
}

.thank-you-message p {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  #thank-you {
    padding: 50px 0;
  }
  
  .thank-you-content {
    padding: 0 15px;
  }
}