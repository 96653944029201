.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 6rem 1rem 4rem;
}

.privacy-policy-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.privacy-policy-content {
  line-height: 1.6;
  text-align: left;
}

.privacy-policy-section {
  margin-bottom: 2rem;
}

.privacy-policy-section h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  text-align: left;
}

.privacy-policy-section p,
.privacy-policy-section ul {
  margin-bottom: 1rem;
  text-align: left;
}

.privacy-policy-section ul {
  padding-left: 1.5rem;
  list-style-type: disc;
}

.policy-date {
  margin-top: 2rem;
  font-style: italic;
  color: #666;
  text-align: left;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 5rem 1rem 3rem;
  }

  .privacy-policy-title {
    font-size: 1.8rem;
  }

  .privacy-policy-section h2 {
    font-size: 1.3rem;
  }
}